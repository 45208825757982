<template>
<div id="app" class="ob home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" page="ob" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #de23c8 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="@/assets/blog6-logo-6.webp" alt="OB WhatsApp APK" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      OBWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>62</strong> | Size: <strong>85MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      OBWhatsApp introduces a variety of features not available in the official WhatsApp. It was created to meet the needs of users who seek more customization and control. It enables users to change themes, hide read receipts, enter DND mode, read deleted messages and more.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">Download OBWhatsApp
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> What is OB WhatsApp Omar?</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Screenshot of OB WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Main Features of OBWhatsApp Latest Version</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Requirements to Install OBWhatsApp APK</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> How to Download OBWhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> OBWhatsApp vs. WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> Backup Data on OBWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Benefits of OBWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> Alternatives of OBWhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> Frequently Asked Questions (FAQs)</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> Conclusion</a>
                      </li>
                    </ul>

                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0"> What is OB WhatsApp Omar?
                  </span>
                </h2>
                <p>
                  OB WhatsApp, developed by Omar Annabi, is one of the pioneering mods of the popular WhatsApp Messenger. It developed plenty features not available in the official WhatsApp, allowing users to personalize their experience and add functionality. Created to meet the needs of users who seek more customization and control, OB WhatsApp includes additional privacy options, unique themes, and advanced messaging settings, making it a popular choice for those who want more than what the official app offers.
                </p>
                <!-- <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp New</a>
                    </div>
                  </div> -->

                <h2 class="h">
                  <span id="TBC_1">Screenshot of OBWhatsApp</span>
                </h2>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/ob-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/ob-1.webp" alt="obwhatsapp screenshot" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">Main Features of OBWhatsApp Latest Version</span>
                </h2>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Theme Customization</h3>
                    <p>
                      Choose from various themes to personalize your chat background, font style, and colors, adding a unique touch to your interface.
                    </p>

                    <h3 class="h3">Hide Online Status</h3>
                    <p>
                      Control your visibility by hiding your online status from others, offering extra privacy and control over your online presence.
                    </p>

                    <h3 class="h3">Anti-Delete Messages</h3>
                    <p>
                      This feature lets you read deleted messages that others may have removed, ensuring you never miss any important information.
                    </p>

                    <h3 class="h3">DND Mode</h3>
                    <p>
                      With Do Not Disturb (DND) mode, you can turn off internet connectivity for OBWhatsApp alone, minimizing distractions and maintaining focus.
                    </p>

                    <h3 class="h3">Increased Media Sharing Limits</h3>
                    <p>
                      Unlike the official app, OBWhatsApp allows you to share larger videos, high-resolution images, and more images at once, ideal for multimedia sharing.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Auto-Reply</h3>
                    <p>
                      Automatically respond to messages even when you're offline or unavailable, making it convenient for users with busy schedules.
                    </p>

                    <h3 class="h3">Status Length</h3>
                    <p>
                      Post longer statuses compared to the official app, allowing you to share more detailed updates or thoughts.
                    </p>

                    <h3 class="h3">Enhanced Privacy Options</h3>
                    <p>
                      Hide features like blue ticks, double ticks, and typing status, giving you greater control over your privacy.
                    </p>

                    <h3 class="h3">Anti-Ban Protection</h3>
                    <p>
                      This anti-ban feature reduces the risk of being banned, allowing you to enjoy the app's mod features safely.
                    </p>
                    <h3 class="h3">Message Scheduler</h3>
                    <p>
                      Schedule messages to be sent at specific times, perfect for reminders, birthday messages, or professional communications.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_3"> Requirements to Install OBWhatsApp APK
                  </span>
                </h2>
                <p>
                  To successfully install and run OBWhatsApp, you need:
                </p>
                <ul>
                  <li>Android device with a version of 4.0 or higher.</li>
                  <li>Minimum 200 MB of free storage.</li>
                  <li>Enable the "Unknown Sources" option in your device's settings to install the APK file from outside the Play Store.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_4"> How to Download OBWhatsApp? </span>
                </h2>
                <p>
                  Follow these steps to download and install OBWhatsApp on your Android device:
                </p>
                <ol>
                  <li>1. <strong>Enable Unknown Sources: </strong>Go to Settings > Security > Enable Unknown Sources.</li>
                  <li>2. <strong>Download the APK: </strong>Visit a trusted source and download the latest OBWhatsApp v62 APK file.</li>
                  <li>3. <strong>Install the APK: </strong>Open the downloaded file and tap on "Install."</li>
                  <li>4. <strong>Open OBWhatsApp: </strong>Once installed, open the app, log in, and start exploring its features.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_5">Backup Data on OBWhatsApp</span>
                </h2>
                <p>
                  Backing up your OBWhatsApp data is simple and ensures that you won't lose your messages or media files if you switch devices or reinstall the app.
                </p>
                <ol>
                  <li>1. <strong>Navigate to the Folder: </strong>Open your file manager and locate the "OBWhatsApp" folder.</li>
                  <li>2. <strong>Copy the Database File: </strong>Find the database file in this folder, which contains your chat backups. Copy this file to another secure location on your device or external storage.</li>
                </ol>
                <p>
                  When you reinstall OBWhatsApp or switch devices, you can paste this database file back into the same folder to restore your chats.
                </p>

                <h2 class="h">
                  <span id="TBC_6"> OBWhatsApp vs. WhatsApp </span>
                </h2>
                <p>
                  Why OBWhatsApp attracts millions of users? Aren't they afraid of personal information leakage? What's the difference between OBWhatsApp and the official WhatsApp? Here we made a clear comparison chart.
                </p>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Features</th>
                        <th>OBWhatsApp</th>
                        <th>WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>System Support</td>
                        <td>Android Only</td>
                        <td>Android, iOS, PC, Mac, Web</td>
                      </tr>
                      <tr>
                        <td>Customer Service</td>
                        <td>No Official Service</td>
                        <td>Contact Official WhatsApp or Check Help Center</td>
                      </tr>
                      <tr>
                        <td>Large Files Sending</td>
                        <td>It supports 2G file sending</td>
                        <td>Only supports 1G file sharing</td>
                      </tr>
                      <tr>
                        <td>Premium Features</td>
                        <td>It added some custom features and privacy options</td>
                        <td>Features are limited and wait for updating. Some features are only available on WhatsApp Business.</td>
                      </tr>
                      <tr>
                        <td>Backup to Google Drive</td>
                        <td>Unofficial apps can't access Google Drive. Users have to back up data to the local folder.</td>
                        <td>Official WhatsApp users can set backup frequency and transfer data to Google Drive under a Google Account.</td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>The default language is Arabic</td>
                        <td>The default language is English</td>
                      </tr>
                    </tbody>
                  </table>

                </figure>

                <h2 class="h">
                  <span id="TBC_7"> Benefits of OB WhatsApp </span>
                </h2>
                <p>
                  OB WhatsApp offers several benefits:
                </p>
                <ul>
                  <li><strong>Free to Use: </strong>OBWhatsApp is free, with no hidden fees or subscription requirements.</li>
                  <li><strong>Enhanced Features: </strong>It provides additional privacy and customization options not found in the official app.</li>
                  <li><strong>Immediate Access to New Updates: </strong>You don't have to wait for the official WhatsApp to release new features; OBWhatsApp brings them directly to you.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8"> Alternatives of OBWhatsApp </span>
                </h2>
                <ul>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-gb`}" class="jump-url">GB WhatsApps</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-fm`}" class="jump-url">FM WhatsApps</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-yo`}" class="jump-url">YOWhatsApp</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:`${selectedLanguage}-plus`}" class="jump-url">WhatsApp Plus</router-link>
                  </li>
                  <li>AN WhatsApp</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_9"> FAQ
                  </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is OBWhatsApp safe to use?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      While OBWhatsApp offers many features, it is a third-party app and not officially supported by WhatsApp. Use caution and download from trusted sources.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Will I get banned for using OBWhatsApp?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      OBWhatsApp includes anti-ban protection, but there's still a small risk as it is not an official version of WhatsApp.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I use OBWhatsApp with the official WhatsApp?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Generally, no. Most mods require you to uninstall the official app first.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">How often should I update OBWhatsApp?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Keep an eye out for updates every few months to stay on top of new features and security improvements.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is OBWhatsApp available for iOS?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      OBWhatsApp is only available for Android devices at this time.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_10"> Conclusion </span>
                </h2>

                <p>
                  OBWhatsApp Omar is a powerful WhatsApp mod that provides features designed for users who want more from their messaging experience. From privacy enhancements to customization options, OBWhatsApp v62 allows users to communicate and manage their chats uniquely and efficiently. However, as a third-party app, users should exercise caution, download from reliable sources, and ensure regular backups to protect their data.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="goto('download')">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
        lang: 'en',
        val: '🇺🇸 English'
      }],
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
